body, html, #root {
  height: 100%;
  margin: 0;
}

.MuiContainer-root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
  overflow: auto;
}
